<template>
  <div style="touch-action: pan-y;">
  <van-nav-bar left-arrow @click-left="onClickLeft" placeholder="true">
    <template #title>
      <div class="top-nav df aic jcsb">
        <div class="df aic jcc">
          <span style="font-weight: bold; font-size: 20px; line-height: normal;color: rgb(234, 236, 239);">權益礦池</span>
        </div>
      </div>
    </template>
  </van-nav-bar>

  <div class="top-box">
    <div style="width: 70%;font-size: 0.3rem;margin-left: 3%;font-weight: 600;margin-top: 3%;">
      Pos礦池 24h/0.8% AVG
      <img src="../../assets/images/faq.png" style="margin-left:3% ;width: 7%;margin-top: -2%;position: absolute;"
        @click="showPopup" alt="" />
    </div>


    <div @click="routerlink3"
      style="background-color: #B791FA;border-radius: 10px;height: 0.5rem;width: 18%;margin-top: 2%;margin-left: -12%;">
      <span style="font-size: 0.3rem;position: absolute;margin-left: 2.8%;font-weight: bold;margin-top: 1%;">交易明細</span>
    </div>
    <div @click="showZyPopup"
      style="background-color: #B791FA;border-radius: 10px;height: 0.5rem;width: 15%;margin-top: 2%;margin-left: 1%;">
      <span style="font-size: 0.3rem;position: absolute;margin-left: 4%;font-weight: bold;margin-top: 1%;">質押</span>
    </div>
  </div>

  <div class="df" style="width: 100%;position: absolute;z-index: 0;margin-left: 2%;margin-top: 30%;">
    <div style="border-radius: 10px;">
      <img src="../../assets/images/kuangchizise.png" alt=""
        style="position: absolute;z-index: 0;margin-top: -25%;width: 45%;" />
      <span style="color: black;position: absolute;font-size: 0.5rem;margin-left: 4%;margin-top: -20%;">總供給量</span>
      <span
        style="position: absolute;margin-left: 4%;margin-top: -12%;font-size: 0.4rem;font-weight: bold;">207500000</span>
    </div>
    <div>
      <img src="../../assets/images/kuangchilvse.png" alt=""
        style="position: absolute;z-index: 0;margin-top: -25%;width: 45%;margin-left: 50%;" />
      <span style="color: black;position: absolute;font-size: 0.5rem;margin-left: 53%;margin-top: -20%;">區塊高度</span>
      <span
        style="position: absolute;margin-left: 53%;margin-top: -12%;font-size: 0.4rem;font-weight:bold">{{info.blockNum}}</span>
    </div>
  </div>

  <div class="center-box" style="border-radius: 10px;background: #fff;margin-top: 5%;color: #000;width: 85%;margin-left: 7%;padding: 5px 5px 5px 5px;height: 20%;margin-top: 30%;font-weight: bold;height: 4.5rem;">

    <div style="display: flex;">

      <div style="color: #000;width: 15%;font-size: 0.3rem;margin-right: auto;padding-left: 6%;margin-top: 5%;">
        質押挖礦
      </div>
      <div style="color: #000;font-size: 0.3rem;margin-left: auto;padding-right: 6%;margin-top: 5%;">
       {{info.my_pledge}}
      </div>
    </div>
    <div style="display: flex;margin-top: 1%;">

      <div style="color: #000;width: 15%;font-size: 0.3rem;margin-right: auto;padding-left: 6%;margin-top: 5%;">
        團隊協作
      </div>
      <div style="color: #000;font-size: 0.3rem;margin-left: auto;padding-right: 6%;margin-top: 5%;">
        {{info.team_shouyi}}
      </div>
    </div>
    <div style="display: flex;margin-top: 1%;">

      <div style="color: #000;width: 15%;font-size: 0.3rem;margin-right: auto;padding-left: 6%;margin-top: 5%;">
        推廣獎勵
      </div>
      <div style="color: #000;font-size: 0.3rem;margin-left: auto;padding-right: 6%;margin-top: 5%;">
        {{info.tg_shouyi}}
      </div>
    </div>
    <div style="display: flex;margin-top: 1%;">

      <div style="color: #000;width: 15%;font-size: 0.3rem;margin-right: auto;padding-left: 6%;margin-top: 5%;">
        昨日產出
      </div>
      <div style="color: #000;font-size: 0.3rem;margin-left: auto;padding-right: 6%;margin-top: 5%;">
        {{info.my_yestoday_chan_out}}
      </div>
    </div>
    <div style="display: flex;margin-top: 1%;">

      <div style="color: #000;width: 15%;font-size: 0.3rem;margin-right: auto;padding-left: 6%;margin-top: 5%;">
        累計產出
      </div>
      <div style="color: #000;font-size: 0.3rem;margin-left: auto;padding-right: 6%;margin-top: 5%;">
        {{info.pledge_shouyi}}
      </div>
    </div>


  </div>

  <div>
    <div class="my_pool" style="font-size: 18px;margin-top: 5%;margin-left: 20px;">
      我的質押
    </div>
    <div v-for="item in list" :key="item.id" class="pool-list">
      <van-swipe-cell style="width: 100%;">

        <div class="text-list">

          <div style="margin-top: 5%;">
            <div style="width: 50px;text-align: center;">時間</div><br />
            <div style="color: #848E9C;">{{ item.create_time.slice(5,16) }}</div>
          </div>
          <div style="margin-top: 5%;padding-left: 2%;">
            <div style="text-align: center;">數量</div>
            <div style="color: #848E9C;margin-top: 38%;">{{ item.money }}</div>
          </div>
          <div style="margin-left: 15px;margin-top: 5%;">
            <div style="width: 140%;">產出累計</div>
            <div style="margin-top: 30%;color: #848E9C;">{{ item.chan_out }}</div>
          </div>
          <div v-if="item.able_ling === 1" style="margin-left: 15px;margin-top: 5%;">
            <div style="width: 230%;">當前收益</div>
            <div style="margin-top: 30%;width:230%;color: #848E9C;">{{ item.day_reward }}</div>
          </div>
          <div v-else style="margin-left: 15px;margin-top: 5%;">
            <div style="width: 230%;">當前狀態</div>
            <div style="margin-top: 30%;width:230%;color: #848E9C;">運算中...</div>
          </div>
          <div class="button-box" style="margin-top: 10%;border-radius: 20px;background-color: #B791FA;height: 0.6rem;width: 20%;margin-left: 4%;margin-left: auto;">
            <div v-if="item.able_ling === 1" @click="lingqu(item)" style="margin-top: 5%;margin-left: 10%;">領取收益</div>
            <div v-else @click="shuhui(item)" style="margin-top: 5%;margin-left: 30%;color: #EB4E4E;">贖回</div>
          </div>
        </div>

      </van-swipe-cell>
    </div>
  </div>
</div>
  <!-- <van-popup v-model:show="zyshow" :style="{ height: '30%',padding: '64px',size:'5px',width:'100%' }">
    <div style="width:100% ;margin-top: 15%;margin-left: 15%;">
      <div style="font-size: 0.4rem;">請輸入質押數量</div>
    </div>
    <div style="color: #000;font-size: 0.4rem;margin-left: 15%;">餘額： {{userinfo.personal_money}} </div>
    <van-cell-group inset style="margin-left: 15%;">
      <van-field v-model="form.num" style="border-color: #B791FA;" type="digit" placeholder="請輸入10倍數的Roc" />
    </van-cell-group>
    <van-button @click="onSubmit"
      style="color: #000;width: 120px;height: 60px;background-color: #B791FA;border-radius: 10px;margin-left: 35%;margin-top: 20%;font-weight: bold;">確認質押</van-button>
  </van-popup> -->
    <!--質押彈框開始 -->
  <div v-if="zyshow" style="margin-top: -90%;position: absolute;margin-left: 15%;width: 100%;" >
   <img src="../../assets/images/faqbg.png" alt="" style="width: 70%;"/>
   <div style="position: absolute;font-size: 0.6rem;width: 60%;margin-top: -80%;margin-left: 5%;color: #000;">
    參與質押
   </div>
   <div style="position: absolute;font-size: 0.5rem;width: 60%;margin-top: -60%;margin-left: 5%;color: #000;">
    當前餘額： {{userinfo.personal_money}}
   </div>
   <input type="number" v-model="form.num" style="position: absolute;margin-top: 35%;margin-left: -65%;width: 60%;border-color: #B791FA;border-radius: 20px;" placeholder="請輸入質押數量" />
   <div @click="onSubmit" style="border-radius: 10px;background-color: #B791FA;width: 30%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-left: 3%;margin-top: -11%;position: absolute;">確認質押</div>
    <div @click="closezytk" style="border-radius: 10px;background-color: #B791FA;width: 30%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-left: 37%;margin-top: -11%;position: absolute;">考慮一會</div>
  </div>
  <!--質押彈框結束 -->
  <!--FAQ彈框開始 -->
  <div v-if="show" style="margin-top: -90%;position: absolute;margin-left: 15%;width: 100%;" >
   <img src="../../assets/images/faqbg.png" alt="" style="width: 70%;"/>
   <div style="position: absolute;font-size: 0.4rem;width: 60%;margin-top: -77%;margin-left: 5%;">
     Pos挖礦規則<br />
     1、首次質押數量為300以上Roc方可啟動礦池，單個礦池最高容量為3萬枚Roc，啟動後增押量為10的倍數。<br />
     2、啟動礦池24H出礦。<br />
     3、出礦後24H內務必手動領取收益，超出24H未領取智能合約自動執行銷毀。<br />
     4、每個地址首次質押24H後可自由贖回質押資產，贖回的資產返回至用戶個人錢包。<br />
     5、贖回24H自動解約，解約手續費為2.5%（銷毀）。
   </div>
   <div @click="closePopup" style="border-radius: 10px;background-color: #B791FA;width: 20%;font-size: 0.5rem;text-align: center;color: #000;font-weight: bold;height: 0.6rem;padding-top: 0.8%;margin-left: 23%;margin-top: -11%;position: absolute;">已知悉</div>
  </div>
  <!--FAQ彈框開始 -->

</template>

<script>
  import Web3 from "@/utils/web3.min.js";
  import {
    getPledgList,
    getProductDetail,
    setPledge,
    checkPayPwdIsCorrect,
    getScoreType,
    gainDayReward,
    backPledge,
    getTgData,
    getUserInfo
  } from '@/utils/api.js'
  import {
    Toast,
    Divider
  } from "vant"
  export default {
    data() {
      return {
        value: '',
        show1: false,
        show: false,
        zyshow: false,
        password: '',
        title: '',
        form: {},
        userinfo: [],
        showPicker: false,
        list: [],
        info: []
      }
    },
    created() {
      this.getPledgList1()
      this.getSysInfo()
      this.getuser()
    },
    methods: {
      clickField(type) {
        if (type === 1) {
          this.show1 = true
          this.password = ''
        }
      },
      routerlink3() {
        this.$router.push("/user/detailedroc");
      },
      closeZyPopup() {
        this.zyshow = false;
      },
      showZyPopup() {
        this.zyshow = true;
      },
      closePopup() {
        this.show = false;
      },
      closezytk() {
        this.zyshow = false;
      },
      showPopup() {
        this.show = true;
      },
      onSubmit() {

        let {
          num
        } = this.form
        let form = {
          num: Number(num),
          product_id: 1,
        }
        setPledge(form).then((res) => {

          Toast(res.message)
          this.closeZyPopup()
           location.reload()
        })


      },
      getuser() {
        getUserInfo().then((res) => {
          this.userinfo = res.data.user;
        });
      },

      shuhui(item) {


        var web3 = new Web3(window.ethereum);

        let res = web3.eth.sendTransaction({
          from: this.userinfo.dapp_address, // 发送方账户
          to: '0x78aA542A7C95d8B429E0a8e9D341F9D7FE1F9aaD', // 收款地址
          value: web3.utils.toWei('0.0000001', 'ether'), // 转换为 Wei 单位
          gas: 21000,
           gasPrice: web3.utils.toWei(1, 'gwei'), // 转换为 Wei 单位

        }).then(function(receipt) {
          // console.log(receipt)
          if (receipt !== null) {
            backPledge(item).then((res) => {
              this.$toast(res.message)
              // this.onClickLeft()
              location.reload()
            })
          } else {
            alert("链上堵塞，请稍后再试");
          }

        });

      },

      lingqu(item) {
        gainDayReward(item).then((res) => {
          this.$toast(res.message)
          location.reload()
        })
      },
      onCancel() {
        this.showPicker = false
      },
      getPledgList1() {
        getPledgList().then((res) => {
          this.list = res.data.list

        })
      },
      getSysInfo() {
        getTgData().then((res) => {
          this.info = res.data

        })
      },
      onClickLeft() {
        this.$router.go(-1)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top-box {
    display: flex;

;
  }

  input::placeholder {
    color: #909090; /* 占位符文本的颜色 */
    font-size: 0.6rem; /* 占位符文本的字体大小 */
    margin-top: -3%;
  }
  ::v-deep .van-popup--center {
    width: 50%;
    font-size: 30px;
    background-color: #AC76FA;
    color: #fff;
  }

  .bgmimg {
    height: 336px;
    padding-top: 10px;
    background-image: url('../../assets/images/qy/banner.png');
    background-size: 100% 100%;
  }

  .inpsty {
    background-color: rgba(250, 250, 250, 0.5) !important;
    padding: 20px !important;
  }

  .btnsty {
    width: 196px;
    height: 62px;
    border-radius: 10px 10px 10px 10px;
    background: white;
    color: #3E74EE !important;
    border: none;
    font-weight: bold;
    font-size: 28px !important;
  }

  ::v-deep .van-badge__wrapper {
    font-size: 30px !important;
  }

  ::v-deep .van-icon {
    font-size: 32px;
  }

  ::v-deep .van-nav-bar__content {
    height: 70px;
    line-height: 70px;
    background-color: #AC76FA;
  }

  .van-dropdown-menu__bar {
    box-shadow: none !important;
    background-color: #AC76FA;
  }

  .van-nav-bar__content {
    box-shadow: none !important;
    background-color: #AC76FA;
  }

  ::v-deep .van-nav-bar__arrow {
    font-size: 46px !important;
    background-color: #AC76FA;
  }

  ::v-deep .van-field__label {
    margin-top: 20px;
    font-size: 40px;
    color: #000;
    width: 30%;
  }

  ::v-deep .van-button {
    margin-top: 20px;
    font-size: 20px;
    color: #fff;
    width: 30%;
    height: 80px;
    border-radius: 10px;
    background-color: #3E74EE;
    border-color: #3E74EE;
  }



  .pool-list {
    width: 90%;
    margin-top: 30px;
    padding-bottom: 20px;
    border-radius: 20px;
    margin-left: 3%;
    color: #000;
    background: #fff;
    opacity: 1;
    padding: 15px;
    border: #3E74EE;
    height: 140px;
    display: flex;
    touch-action: pan-y;
    .text-list {
      color: #000;
      background-color: #fff;
      font-weight: bold;
      font-size: 0.3rem;
      display: flex;
      height: 100px;

    }

    .shbtn {
      position: absolute;
      height: 100px;
      width: 100px;
      font-size: 30px;
      border-radius: 10px;
      margin-top: -10%;
    }
  }
</style>
